.nav-actions-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
}
.nav-actions-container.nowrap {
    flex-wrap: nowrap
}

.nav-side {
    display: block
}
nav > .nav-tabs {
    display: flex
}
.nav-header, .nav-header.hidden-tabs-app {
    padding-left: 24px
}
header > .nav-icon {
    display: none
}

.nav-header.minimal-header > .nav-icon {
    display: none;
}

@media (max-width: 1650.05px) {
    .tabs-8 .nav-side, .tabs-8 .nav-tabs {
        display: none
    }
    .tabs-8 .nav-header {
        padding-left: 0
    }
    .tabs-8 .nav-header.hidden-tabs-app {
        padding-left: 24px
    }
    .tabs-8 .nav-icon {
        display: flex
    }
}

@media (max-width: 1340.05px) {
    .tabs-7 .nav-side, .tabs-7 .nav-tabs {
        display: none
    }
    .tabs-7 .nav-header {
        padding-left: 0
    }
    .tabs-7 .nav-header.hidden-tabs-app {
        padding-left: 24px
    }
    .tabs-7 .nav-icon {
        display: flex
    }
}

@media (max-width: 1240.05px) {
    .tabs-6 .nav-side, .tabs-6 .nav-tabs{
        display: none
    }
    .tabs-6 .nav-header {
        padding-left: 0
    }
    .tabs-6 .nav-icon {
        display: flex
    }
}

@media (max-width: 959.05px) {
    .nav-side, nav > .nav-tabs {
        display: none
    }
    .nav-header, .nav-header.hidden-tabs-app {
        padding-left: 0
    }
    header > .nav-icon {
        display: flex
    }
    .nav-header.minimal-header {
        padding-left: 24px;
    }
}

.tabs-7 .tab-root, .tabs-8 .tab-root {
    padding: 6px
}

@media (min-width: 1550px) {
    .tabs-7 button.tab-root, .tabs-8 button.tab-root {
        padding: 6px 20px
    }
}
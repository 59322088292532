@font-face {
  font-family: 'OSGill';
  src: url('./client/fonts/OSGill-Regular.woff2') format('woff2'),
  url('./client/fonts/OSGill-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333333;
}

#root {
  display: flex;
  flex-direction: column;
}

@media print {
  #root {    
    display: block;
  }
}

/* The following should set up all the links in the app to follow the OS digital guidelines*/
/* https://app.zeplin.io/project/5a37db29b96bf702083c7b72/screen/5bc5f7ebb5263a09ce3a195d*/
.emptyLinkButton, a {
  display: inline-flex;
  font-size: 1rem;
  font-family: Source Sans Pro, sans-serif;
  color: #0071bc;
  text-decoration: underline;
  cursor: pointer;
}

.emptyLinkButton:hover,
a:hover {
  color: #005085 !important;
  text-decoration-thickness: 2px;
}

.emptyLinkButton:visited,
a:visited {
  color: #4c2c92;
}

.emptyLinkButton {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

/* Default settings for the material icons from google */
.material-symbols-outlined {
  font-variation-settings:
         'FILL' 0,
         'wght' 400,
         'GRAD' 0,
         'opsz' 24
}

.MuiDialog-container:focus {
  /*  Prevents the "accessible border" from appearing
      when MUI Dialogs are opened. */
  box-shadow: none !important;
}